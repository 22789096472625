import { ModelSelect } from 'vue-search-select';
import Datepicker from 'vuejs-datepicker';
import taxgroup from '../../taxgroup';
export default {
    name: 'TaxTypeDetails',
    components: {
        ModelSelect,
        Datepicker,
        taxgroup
    },
    data() {
        return {
            showTaxTypeDetails: false,
            showTaxGroupList: false,
            loader: false,
            editMode: false,
            updateMode: false,
            showAlert: false,
            isSuccess: false,
            responseMsg: '',
            form: {
                group: null,
                groupId: 0,
                taxType: null,
                taxTypeId: 0,
                status: false
            }
        }
    },
    mounted() {
        this.eventBus.$off('createFormActions');
        this.eventBus.$off('taxTypeDetails');
        this.eventBus.$on('createFormActions', actionName => {
            if (actionName === 'edit' || actionName === 'update') {
                this.editMode = false;
                this.updateMode = true;
            }
            if (actionName === 'add') {
                this.resetform();
                this.showAlert = false;
                this.editMode = true;
                this.updateMode = true;
                this.showTaxTypeDetails = true;
            }
            if (actionName === 'save' && this.updateMode) {
                this.addEditTaxType();
            }
            if (actionName === 'upload') {

                this.eventBus.$emit('commonUpload', { id: this.form.groupId});
    
              }
        });
        this.eventBus.$on('taxTypeDetails', Obj => {
            this.form.group = Obj.tax_group;
            this.form.groupId = Obj.tax_group_id;
            this.form.taxType = Obj.tax_type;
            this.form.taxTypeId = Obj.tax_type_id;
            this.form.status = Obj.active;
        });
    },
    methods: {
        addEditTaxType() {
            const payload = {
                active: this.form.status,
                tax_group_id: this.form.groupId,
                tax_type: this.form.taxType,
                tax_type_id: this.form.taxTypeId
            };
            this.$store.dispatch('defineTaxType/addEditTaxType', payload)
            .then(response => {
                this.$emit('updateTaxTypeList');
                this.editMode = false;
                this.updateMode = false;
                this.showAlert = true;
                this.loader = false;
                if (response.status === 201) {
                  this.isSuccess = true;
                  this.responseMsg = response.data.message;
                } else {
                  this.isSuccess = false;
                  this.responseMsg = response.response.data.message;
                }
            });
        },
        openTaxGroupList(){
            if (this.editMode) {
                this.showTaxGroupList = true;
            }
        },
        selectTaxGroup(item){
            this.form.group = item.tax_group;
            this.form.groupId = item.tax_group_id;
            this.showTaxGroupList = false;
        },
        hideTaxGroupList() {
            this.showTaxGroupList = false;
        },
        resetform() {
            this.form.group = null;
            this.form.groupId = 0;
            this.form.taxType = null;
            this.form.taxTypeId = 0;
            this.form.status = true;
        }
    }
}